header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 30px;

    .slinky-icon {
        height: 30px;
        pointer-events: none;
        user-select: none;
        margin-bottom: 10px;
    }

    h1 {
        max-width: fit-content;
        font-size: 28px;
        font-weight: 900;
        letter-spacing: -1px;
        padding-bottom: 4px;
    }

    .subtitle {
        margin-top: 4px;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 1.5em;
        position: relative;
        width: 100%;

        .nudge {
            position: absolute;
            background: linear-gradient(
                to right,
                #000 0%,
                #3700b3 50%,
                #dab2ff 60%,
                #7e39fb 66%,
                #3700b3 70%,
                #000 100%
            );
            background-clip: text;
            color: rgba(0, 0, 0, 0);
            background-size: 800%;
            animation: nudge-bg-anim 3s ease-in-out reverse;

            @keyframes nudge-bg-anim {
                to {
                    background-position: 100% 800%;
                }
            }
        }
    }

    h2,
    .nudge {
        font-size: 12px;
        line-height: 1.35;
        font-weight: 400;
        margin-top: 4px;
        color: #666;
        padding-bottom: 2px;
        will-change: transform, opacity;
    }
}
