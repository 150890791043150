.link-block {
    order: -1;
    position: relative;
    display: flex;
    align-items: center;
    outline: none;
    max-width: 100%;
    overflow: hidden;
    border-radius: 10px;

    &:hover,
    &:focus-within {
        button {
            opacity: 1;
        }
    }

    &:active {
        transform: scale(0.995);
    }

    img {
        height: 16px;
        width: 16px;
        position: absolute;
        left: 10px;
        pointer-events: none;
        user-select: none;
    }

    a {
        width: 100%;
        height: 100%;
        border-radius: 10px;
        border: 1px solid #eee;
        background-color: #fff;
        display: flex;
        align-items: center;
        color: #000;
        text-decoration: none;
        height: 40px;
        padding: 10px;
        padding-left: calc(16px + 20px);
        outline: none;

        @media (max-width: 1000px) {
            height: 45px;
            padding-right: 35px;
        }

        .link-label,
        .link-title {
            max-width: 100%;
            text-overflow: ellipsis;
            -o-text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            line-height: 1.25;
            font-size: 12px;
        }

        &:hover,
        &:focus-visible,
        &:focus-within {
            background-color: #f5f5f5;
            border: 1px solid #f5f5f5;
            padding-right: 40px;
        }

        &:active {
            background-color: #eee;
            border: 1px solid #eee;
        }
    }

    button {
        background-color: #f5f5f5;
        border: none;
        position: absolute;
        height: 30px;
        width: 30px;
        right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        border-radius: 500px;
        padding: 0;
        outline: none;
        color: #000;
        transition: all 0.2s var(--spring-ease-1);
        opacity: 0;

        &:hover,
        &:focus-visible {
            background-color: #f5f5f5;
        }

        &:active {
            transform: scale(0.95);
            background-color: #ddd;
        }

        .material-icons {
            height: 16px;
            width: 16px;
            font-size: 16px;
        }

        @media (max-width: 1000px) {
            opacity: 1;
            background-color: #fff;
        }
    }
}