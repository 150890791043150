.main {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    height: 100vh;

    .slinky-card {
        background-color: #fff;
        height: fit-content;
        padding: 30px 20px;
        padding-top: 10vh;
        border-radius: 20px;
        width: 100%;
        max-width: 550px;

        &::-webkit-scrollbar {
            width: 1em;
        }

        @media (max-width: 1000px) {
            padding-top: 8vh;
        }
    }

    .main-wrapper {
        width: 100%;
    }
}

.inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100px;
    --input-transition: all 0.4s var(--spring-ease-1);

    .newLinkForm,
    .slinkyCodeForm {
        position: relative;
        display: flex;
        align-items: center;
        --icon-size: 16px;
        margin-bottom: 1px;
        width: calc(100% - 20px);
        will-change: width;
        transition: var(--input-transition);

        &.has-value {
            label {
                opacity: 0;
            }
            input {
                color: #000;
            }
        }

        &:hover {
            input {
                background-color: #e5e5e5;
            }
        }

        &:focus-visible,
        &:focus-within {
            margin-bottom: 0;
            --icon-size: 22px;
            width: 100%;

            label {
                transform: translateY(-90%);
            }

            input {
                padding-top: 16px;
                height: 55px;
                border-radius: 10px;
                border-color: #000;
                color: #000;
                outline: none;
                background-color: #fff;

                &::placeholder {
                    opacity: 1;
                }
            }

            .input-btn {
                opacity: 1;

                &:hover,
                &:focus-visible {
                    outline: none;
                    background-color: #eee;
                    transform: scale(1.15);
                }

                &:active {
                    background-color: #ddd;
                    transform: scale(0.975);
                }
            }

            &.has-value {
                label {
                    opacity: 1;
                }
            }
        }

        .input-icon {
            position: absolute;
            font-size: var(--icon-size);
            height: var(--icon-size);
            width: var(--icon-size);
            left: 10px;
            color: #000;
            pointer-events: none;
            transition: var(--input-transition);
            will-change: color, height, width, font-size;
        }

        label {
            pointer-events: none;
            position: absolute;
            left: calc(var(--icon-size) + 20px);
            font-weight: 400;
            font-size: 12px;
            color: #888;
            letter-spacing: 0.1px;
            transition: var(--input-transition);
            will-change: transform, opacity;
        }

        input {
            font-family: var(--inter-font-family);
            width: 100%;
            font-size: 12px;
            background-color: #eee;
            border: 1px solid #eee;
            color: #888;
            padding-left: calc(var(--icon-size) + 20px);
            padding-right: 20px;
            font-weight: 400;
            height: 30px;
            border-radius: 10px 10px 0 0;
            transition: var(--input-transition);
            -webkit-appearance: none;
            -moz-appearance: none;
            will-change: height, padding;

            &::-webkit-calendar-picker-indicator,
            &::-webkit-list-button {
                display: none !important;
                background-color: inherit;
                opacity: 0;
            }

            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type="number"] {
                -moz-appearance: textfield;
            }

            &::placeholder {
                opacity: 0;
                transition: var(--input-transition);
            }

            @media (max-width: 1000px) {
                height: 35px;
            }
        }

        .input-btn {
            opacity: 0;
            position: absolute;
            right: 10px;
            border: none;
            background-color: rgba(0, 0, 0, 0);
            color: #000;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 30px;
            width: 30px;
            cursor: pointer;
            padding: 0;
            border-radius: 500px;
            transition: var(--input-transition);

            .material-icons {
                font-size: 20px;
                height: 20px;
                width: 20px;
                transform: rotate(-90deg);
            }
        }
    }

    .slinkyCodeForm {
        input {
            border-radius: 0 0 10px 10px;
        }
        &.has-value {
            button {
                opacity: 1;
            }
        }
    }
}

.my-qr-block {
    border: 1px solid #eee;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    --qr-image-size: 100px;
    margin-bottom: 30px;
    will-change: transform, opacity;

    .my-qr-image {
        background-color: #fff;
        height: var(--qr-image-size);
        width: var(--qr-image-size);
        cursor: zoom-in;
        transition: all 0.6s var(--spring-ease-2);
        will-change: transform;
        padding: 0;
        border: none;
        border-radius: 10px;

        img {
            border-radius: 10px;
            border: 0.5px solid #fff;
        }

        &.zoomed-in {
            transform: scale(2);
            cursor: zoom-out;

            img {
                border: 0.5px solid #000;
            }
        }
    }

    .my-qr-copy {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .my-qr-description {
            font-size: 12px;
            color: #999;
            line-height: 1.35;
            max-width: 250px;
        }

        .my-qr-code {
            font-size: 28px;
        }
    }
}

.my-links-btn {
    font-family: var(--inter-font-family);
    align-self: flex-start;
    border: none;
    background-color: #fff;
    color: #aaa;
    display: flex;
    align-items: center;
    gap: 5px;
    cursor: pointer;
    padding: 4px 12px 4px 8px;
    border-radius: 500px;
    transition: all 0.2s var(--spring-ease-1);

    &:hover,
    &:focus-visible {
        background-color: #eee;
        color: #000;
    }

    &:active {
        transform: scale(0.95);
    }

    .material-icons {
        font-size: 12px;
        height: 12px;
        width: 12px;
    }
}

.links-list-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-top: 10px;
    padding: 0 10px;
    margin-bottom: 10px;

    h3 {
        font-size: 16px;
        font-weight: 800;
        letter-spacing: -0.15px;
    }

    button {
        border: none;
        background-color: rgba(255, 255, 255, 0);
        text-transform: uppercase;
        font-size: 10px;
        letter-spacing: 0.65px;
        color: #aaa;
        padding: 0;
        cursor: pointer;
        border-radius: 500px;
        padding: 4px 10px;
        transition: all 0.2s var(--spring-ease-1);
        outline: none;

        &:hover,
        &:focus-visible {
            color: #000;
            background-color: #eee;
        }

        &:active {
            background-color: #ddd;
            transform: scale(0.95);
        }
    }
}

.loading-message {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    font-size: 12px;
    color: #aaa;
}

.links-list {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .link-block:last-child{
        margin-bottom: 4rem;
    }
}
