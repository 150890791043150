:root {
    --inter-font-family: "Inter", sans-serif;
    --spring-ease-1: cubic-bezier(0, -0.01, 0, 1.25);
    --spring-ease-2: cubic-bezier(0.37, 0.01, 0, 1.15);
}
/* defaults and resets */
html {
    font-family: "Inter", sans-serif;
    scroll-behavior: smooth;
    scroll-padding: 100px;
    box-sizing: border-box;
}
@supports (font-variation-settings: normal) {
    html {
        font-family: "Inter var", sans-serif;
    }
}
*,
*::before,
*::after {
    box-sizing: inherit;
}
body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
p {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-family: var(--inter-font-family);
}
a[href]:empty::before {
    content: attr(href);
}
input[type="text"],
input[type="number"],
select,
textarea {
    font-size: 16px;
    font-family: var(--inter-font-family);
}
img {
    max-width: 100%;
}
.material-icons {
    font-family: "Material Icons Rounded";
    font-weight: normal;
    font-style: normal;
    font-size: 24px; /* Preferred icon size */
    height: 24px;
    width: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;
    overflow: hidden;
    user-select: none;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: "liga";
}